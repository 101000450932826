import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
  Button,
  Dialog,
} from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import Image from 'next/image';
import { useState } from 'react';

import { Icon } from '@/components/shared/icon';
import { useDAL } from '@/data/dal';
import { ApplicationsLogos } from '@/data/inventory/data';

function appsState(
  entities: InventoryResource[]
): Map<string, { intermediate: boolean; checked: boolean }> {
  const apps = Object.keys(ApplicationsLogos).filter((x) => x !== 'Unknown');
  const appsSelected = apps.map((app) => {
    const allEntitiesHaveApp = entities.every((entity) =>
      entity.apps?.includes(app)
    );
    const someEntitiesHaveApp = entities.some((entity) =>
      entity.apps?.includes(app)
    );
    return {
      intermediate: !allEntitiesHaveApp && someEntitiesHaveApp,
      checked: allEntitiesHaveApp,
      app,
    };
  });
  return new Map(appsSelected.map((x) => [x.app, x]));
}

export function AppsEditor({
  open,
  onClose,
  entities,
}: {
  open: boolean;
  onClose: () => void;
  entities: InventoryResource[];
}) {
  const [applications, setApplications] = useState(appsState(entities));
  const [updating, setUpdating] = useState(false);
  const dal = useDAL();

  const onSave = () => {
    setUpdating(true);
    const selected = [...applications.entries()]
      .filter(([, state]) => state.checked)
      .map(([key]) => key);

    void dal.inventory.apps
      .override(
        entities.map((e) => e.id),
        selected
      )
      .then(() => {
        for (const entity of entities) {
          entity.apps = selected;
          entity.areApplicationsOverridden = true;
        }
      })
      .finally(() => {
        onClose();
        setUpdating(false);
      });
  };
  if (!open) {
    return null;
  }
  return (
    <>
      <Dialog open={open}>
        <Box
          sx={{
            m: 0,
            p: 0,
          }}
        >
          <Stack className='h-full'>
            <Stack
              justifyContent='space-between'
              direction='row'
              alignItems='center'
              padding='16px 24px'
            >
              <Stack alignItems='center' direction='row'>
                <div className='flex flex-col'>
                  <Typography className='font-semibold'>
                    Override applications
                  </Typography>
                </div>
              </Stack>
              <div className='flex gap-4'>
                <Icon
                  onClick={onClose}
                  iconClass='material-symbols-close-rounded'
                />
              </div>
            </Stack>
            <Box
              sx={{
                paddingLeft: '40px',
                paddingRight: '40px',
                maxWidth: '630px',
              }}
            >
              {' '}
              <Typography sx={{ lineHeight: '20px' }} className='font-light'>
                {`Eon won't automatically classify applications for the selected resources. This could change the applied backup policies and controls.`}
              </Typography>
            </Box>
            <Divider sx={{ marginTop: '24px', marginBottom: '12px' }} />
            <div className='mt-4'>
              <Box
                sx={{
                  overflowY: 'scroll',
                  maxHeight: '312px',
                  marginLeft: '16px',
                }}
              >
                {Object.keys(ApplicationsLogos)
                  .filter((x) => x !== 'Unknown')
                  .map((x: string) => (
                    <Stack key={x} direction='row' sx={{ height: '48px' }}>
                      <FormControlLabel
                        title={x}
                        className='w-full'
                        control={
                          <Stack direction={'row-reverse'} alignItems='center'>
                            <Box
                              sx={{
                                width: '30px',
                                height: '30px',
                                marginRight: '12px',
                              }}
                            >
                              <Image alt={x} src={ApplicationsLogos[x]} />
                            </Box>
                            <Checkbox
                              sx={{ padding: '12px 12px 12px 30px' }}
                              size='small'
                              indeterminate={applications.get(x)?.intermediate}
                              checked={applications.get(x)?.checked}
                              onChange={() => {
                                const state = applications.get(x);
                                if (!state) {
                                  return;
                                }
                                const checked = !state.checked;
                                const intermediate = false;
                                setApplications(
                                  new Map([
                                    ...applications,
                                    [x, { checked, intermediate }],
                                  ])
                                );
                              }}
                            />
                          </Stack>
                        }
                        label={x}
                      />
                    </Stack>
                  ))}
              </Box>
            </div>
            {/* Create two buttons at the bottom, aligned to the right */}
            <Stack
              justifyContent='flex-end'
              direction='row'
              padding='16px 24px'
            >
              <Box>
                <Button onClick={onClose}>
                  <Typography>Cancel</Typography>
                </Button>
              </Box>
              <Box marginLeft='12px'>
                <Button
                  onClick={onSave}
                  variant='outlined'
                  disabled={
                    [...applications.values()].some((x) => x.intermediate) &&
                    !updating
                  }
                >
                  {updating ? (
                    <i className='line-md-loading-loop w-[20px] h-[20px]' />
                  ) : (
                    <Typography>Override</Typography>
                  )}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
}
