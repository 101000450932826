import type { InventoryResource } from '@repo/api-gw-sdk';

import { useDAL } from '@/data/dal';

import { RestartAutoClassificationPanel } from './resumeAutoClassifcationPanel';

export function RestartAutomaticDataClassClassification({
  open,
  onClose,
  entities,
}: {
  open: boolean;
  onClose: () => void;
  entities: InventoryResource[];
}) {
  const dal = useDAL();

  const onSave = () => {
    void dal.inventory.dataClasses
      .deleteOverride(entities.map((e) => e.id))
      .then(() => {
        for (const entity of entities) {
          entity.isDataClassesOverridden = false;
        }
      })
      .finally(onClose);

    onClose();
  };
  if (!open) {
    return null;
  }
  return (
    <RestartAutoClassificationPanel
      open={open}
      onClose={onClose}
      onSave={onSave}
      title='Resume auto-classification for data classes'
      description='Eon will automatically classify the selected resources, which removes any overrides. This could change the applied backup policies and controls.'
    />
  );
}
