import { Typography } from '@mui/material';

interface PaginationTitleProps {
  entityLabel: string;
  selection: {
    isAllSelected?: boolean;
    isSelectable: boolean;
    selectedRecordsCount?: number;
  };
  isLoading: boolean;
  recordsCount: number;
}

export default function PaginationTitle(props: PaginationTitleProps) {
  const { entityLabel, selection, isLoading, recordsCount } = props;
  const { selectedRecordsCount, isSelectable, isAllSelected } = selection;

  let title = '';

  if (isLoading) {
    title = 'Loading...';
  }
  if (!isLoading) {
    if (isSelectable && selectedRecordsCount && selectedRecordsCount > 0) {
      title = isAllSelected
        ? `All ${selectedRecordsCount ?? 0} ${entityLabel} on this page are selected`
        : `${selectedRecordsCount ?? 0} ${entityLabel} on this page are selected`;
    } else if (recordsCount > -1) {
      title = `${recordsCount ?? 0} ${entityLabel} found`;
    }
  }

  return (
    <Typography
      color={'var(--mui-palette-text-primary)'}
      lineHeight='24px'
      component={'span'}
      className={'ml-[12px]'}
    >
      {title}
    </Typography>
  );
}
